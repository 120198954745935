<template>
  <div class="step__body">
    <div v-for="item in groups" :key="item.id" class="option option-walls">
      <div class="option-walls__left">
        <div class="option__name">{{ item.name }}</div>
        <div class="option__controls">
          <div v-for="option in item.options" :key="option.id" class="form-check">
            <input class="form-check-input" type="checkbox" value="" v-model="option.selected" :id="`custom-check${option.id}`" @click="onClickOption(option)">
            <label class="form-check-label" :class="{ 'disabled': option.disabled }" :for="`custom-check${option.id}`">
              {{ option.name }}
              <span v-if="option.price.total === 0" class="option__controls-price">
                {{ $filters.currency(option.price.self) }}<span>m2</span>
              </span>
              <span v-else class="option__controls-price">{{ $filters.currency(option.price.total) }}</span>
            </label>
          </div>
        </div>
        <a v-if="item.pdf_link" :href="item.pdf_link" class="download-link mt-3"><i class="fas fa-file-pdf me-2"></i>Technische Infos als PDF</a>
      </div>
      <div class="option-walls__right">
        <div class="tooltip-box text-end">
          <Tooltip v-if="item.tooltip" :tooltip="item.tooltip">
            <button class="btn-reset btn-tooltip">
              <i class="fas fa-info-circle"></i>
            </button>
          </Tooltip>
        </div>
        <img :src="$filters.image(item.image, '190x')" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const options = computed(() => {
      return store.getters['configurator/currentOptions']
    })
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const checkOptionsGroup = computed(() => {
      const opts = options.value.filter(o => o.group === 'screen' || o.group === 'glass')
      if (opts.length > 0) {
        return true
      } else {
        return false
      }
    })
    const groups = computed(() => {
      const cats = step.value.cats
      const options = step.value.options
      const arr = []
      cats.forEach(cat => {
        const items = options.filter(o => o.group === cat.cat)
        arr.push({
          id: cat.id,
          slug: cat.cat,
          name: cat.name,
          image: cat.image,
          tooltip: cat.tooltip,
          description: cat.description,
          pdf_link: cat.pdf_link,
          options: items
        })
      })
      return arr
    })
    function onClickOption (option) {
      if (option.disabled) {
        return
      }
      if (option.type === 'check') {
        store.dispatch('configurator/toggleOption', option)
      }
    }
    return {
      options,
      step,
      groups,
      checkOptionsGroup,
      onClickOption
    }
  }
}
</script>
